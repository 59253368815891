.MobileMenu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
	padding: 60px 23px 0 23px;
	height: 100%;
	width: 100%;
	display: block;
	pointer-events: none;

	@include for-size(tablet-portrait-up) {
		display: none;
	}

	.overviewButton {
		position: fixed;
		right: 20px;
		top: 10px;
		pointer-events: auto;
	}

	.menuItems {
		opacity: 0;
		transform: translateY(-100vh);
		pointer-events: auto;
		height: 100%;
		overflow: scroll;
		padding-bottom: 60px;

		> div > a {
			text-transform: uppercase;
			font-size: 22px;
			line-height: 2;
			font-weight: 500;
			letter-spacing: 3.1;
			display: block;
		}
	}

	//todo: combine rules with desktop other hamburger menu
	.overviewButton.active .hamburger {
		.line {
			&:nth-child(1) {
				transform: translateY(5px);
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-5px);
				opacity: 0;
			}
		}
	}

	.projectMenuContainer {
		//height: 0;
		overflow: hidden;

		.projectMenuList {
			padding: 15px 0;
			margin-bottom: 0;
		}
	}

	.contact.section {
		margin-top: 30px;

		.title-dot {
			position: static;
			display: block;
			margin-bottom: 10px;
		}
	}
}
