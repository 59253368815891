.contact.section {

	.inner {
		flex-direction: column;

		@include for-size(tablet-portrait-up) {
			flex-direction: row;
		}
	}

	.title {
		display: none;
		font-size: 16px;
		margin-bottom: 20px;

		@include for-size(tablet-portrait-up) {
			display: block;
		}
	}

	p, a {
		font-size: 16px;
		line-height: 1.4;

		@include for-size(tablet-portrait-up) {
			font-size: 22px;
		}
	}

	a {
		text-decoration: underline;
	}

	.contactCol {
		width: 100%;
		flex: 0 0 auto;
		padding: 0;

		@include for-size(tablet-portrait-up) {
			width: 33.33%;
			padding: 0 40px;
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	.description.contactCol {
		p {
			font-size: 12px;
			margin-bottom: 20px;

			@include for-size(tablet-portrait-up) {
				font-size: 18px;
				margin-bottom: 0;
			}
		}
	}

	.email.contactCol {
		margin-bottom: 20px;

		@include for-size(tablet-portrait-up) {
			margin-bottom: 0;
		}
	}

	.socials.contactCol {
		li {
			float: left;
			padding-right: 15px;

			@include for-size(tablet-portrait-up) {
				float: none;
				padding-right: 0;
			}
		}
	}

}
