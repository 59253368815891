body {
	font-family: $font-nbint-reg;
}

h1,
h2,
h3 {
	font-weight: normal;
}

.top-title {
	h2 {
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 3px;
	}
}
