.AudioPlayer {
	padding-left: 50px;

	@include for-size(tablet-portrait-up) {
		padding-left: 70px;
	}

	audio {
		display: none;
	}

	.player-ui {
		position: absolute;
		transform: translateX(-100%);
		padding-right: 25px;
		padding-left: 10px;

		@include for-size(tablet-portrait-up) {
			padding-right: 40px;
		}

		.player-button {
			width: 20px;
			height: 20px;
			border-radius: 100%;
			position: relative;
			margin-top: 2px;
			margin-bottom: 2px;

			@include for-size(tablet-portrait-up) {
				width: 22px;
				height: 22px;
				margin-top: 8px;
				margin-bottom: 8px;
			}

			svg {
				display: none;
				position: absolute;

				&.play {
					display: block;
					transform: scale(0.7);
					top: 4px;
					left: 6px;

					@include for-size(tablet-portrait-up) {
						top: 5px;
						left: 7px;
					}
				}

				&.pause {
					top: 7px;
					left: 7px;
					transform: scale(1.2);

					@include for-size(tablet-portrait-up) {
						top: 8px;
						left: 8px;
					}
				}
			}
		}

		.time-stamp {
			transform: rotate(-90deg);
			display: inline-block;
			position: absolute;
			left: 7px;
			font-size: 10px;
			bottom: -42px;
			width: 30px;
			height: 15px;
			letter-spacing: 3px;
			font-weight: bold;
		}

		.time-filler {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 0%;
			opacity: 0.1;
			z-index: -1;
		}
	}

	.player-speech {
		overflow: hidden;
		width: 100%;
		font-size: 18px;
		line-height: 24px;
		max-width: 800px;

		@include for-size(tablet-portrait-up) {
			font-size: 25px;
			line-height: 1.25;
			line-height: 38px;
		}

		@include for-size(desktop-up) {
			font-size: 29px;
		}

		.marker {
			position: relative;
			padding: 0 0.1em;
			float: left;

			span {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				z-index: -1;
				transition: opacity 0.6s;
			}

			&.active {
				span {
					opacity: 0.1;
				}
			}
		}
	}
}
