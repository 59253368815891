.IntroductionModule {
	.innerWrapper {
		display: flex;
	}

	.introText {
	}

	.introText p {
		font-size: 16px;
		line-height: 1.4;
		max-width: 500px;
		width: 90vw;

		@include for-size(tablet-portrait-up) {
			font-size: 26px;
			line-height: 1.25;
		}
	}

	.vimeoVideoWrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.icon-mobile-video-play {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 36px;
			margin-left: -18px;
			margin-top: -18px;

			@include for-size(tablet-portrait-up) {
				display: none;
			}
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			pointer-events: none;
			height: 100%;
			width: 100%;
		}
	}

	.introMedia {
		//margin-top: 33vh;
		margin-left: 80px;

		@include for-size(tablet-portrait-up) {
			margin-left: 170px;
		}

		.introImage {
			will-change: transform;
		}

		.media {
			max-width: 700px;
			width: 90vw;

			.mediaWrapper {
				position: relative;
			}

			@include for-size(tablet-portrait-up) {
				width: 100%;
			}

			img {
				display: block;
				pointer-events: none;
				width: 100%;
			}

			video.loop {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
			}
		}

		.introDate {
			max-width: none;
			font-weight: 500;
			margin-bottom: 13px;
		}

		.introVenue,
		.introLocation {
			max-width: 280px;
		}

		.info {
			margin-bottom: 20px;

			@include for-size(tablet-portrait-up) {
				margin-bottom: 40px;
			}
		}
	}
}
