.slideshowBackground {
	position: absolute;
	top: -42px;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 3;
	visibility: hidden;
	pointer-events: auto;

	.background {
		background-color: #000000;
		width: 100%;
		height: 100%;
		will-change: opacity;
	}
}

.slideshowForeground {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 120px;
	z-index: 8;
	visibility: hidden;
	pointer-events: auto;

	.close {
		position: absolute;
		z-index: 6;
		bottom: 60px;
		left: calc(50% - 18px);
		opacity: 0.85;

		.circle {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			background-color: black;
			width: 34px;
			height: 34px;
			border-radius: 50%;
		}

		&:hover {
			opacity: 1;
		}
	}

	.counter {
		position: absolute;
		left: 50%;
		bottom: 40px;
		width: 100px;
		margin-left: -50px;
		text-align: center;
		color: $color-white;
		font-size: 11px;
		letter-spacing: 2px;
	}
}

.slideshowModule {
	.images {
		position: relative;
		z-index: 1;
		will-change: transform;
		pointer-events: auto;
	}

	.mobileIcon {
		position: absolute;
		right: 20px;
		bottom: 20px;
		display: none;
		z-index: 1;

		&.active {
			display: block;

			@include for-size(tablet-portrait-up) {
				display: none;
			}
		}
	}

	.featuredImage {
		display: inline-block;
		position: relative;

		top: 0;
		left: 0;

		&.over {
			background-color: $color-black;
		}

		img {
			will-change: transform;
		}
	}

	.image {
		&.otherImage {
			position: absolute;
			top: 0;
			text-align: center;
		}

		img {
			display: block;
			pointer-events: none;
			height: 60vw;
			max-height: 450px;
		}
	}
}
