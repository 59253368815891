html,
body {
	margin: 0;
	padding: 0;
	min-height: 100%;
	min-width: 100%;
}

html.noCursor,
html.noCursor body {
	cursor: none !important;
}

body {
	background-color: $color-bg;
	-webkit-tap-highlight-color: transparent;
	color: $color-black;
	font-size: 16px;
	font-weight: 300;
	will-change: background;
	overflow: hidden;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	box-sizing: border-box;
}

button {
	background: transparent;
	border: 0;
	outline: none;
	padding: 0;
}

a,
a:visited,
a:active,
a:hover {
	color: inherit;
	text-decoration: none;
	cursor: none !important;
}

p {
	font-size: 14px;
	line-height: 1.5;
	margin: 0;

	@include for-size(tablet-portrait-up) {
		font-size: 16px;
	}
}

.smallUppercase {
	font-size: 10px;
	letter-spacing: 3.5px;
	line-height: 15px;
	text-transform: uppercase;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.logo {
	position: fixed;
	top: 0;
	left: 0;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: 3.64px;
	z-index: 6;

	a {
		display: inline-block;
		padding: 15px $site-padding-s;
		font-weight: 400;
	}
}

#SiteWrapper {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bullet {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	background-color: #000;
}

.title-dot {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: $color-black;
	top: 0px;
	left: 0;
}

.hamburger {
	width: 47px;
	height: 20px;
	float: left;
	pointer-events: none;

	.line {
		height: 1px;
		width: 100%;
		background-color: #000;
		margin: 4px 0;
		transition: all 0.4s;
	}
}

.mobile-compass {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 4;
	opacity: 0;

	@include for-size(tablet-portrait-up) {
		display: none;
	}
}

[data-color-prop='color'] {
	will-change: color;
}

[data-color-prop='backgroundColor'] {
	will-change: background-color;
}

.sf-toolbar {
	display: none !important;
}
