.mobileProjectNav {
	position: fixed;
	left: 0;
	bottom: -60px;
	height: 60px;
	width: 100%;
	z-index: 4;
	display: block;

	@include for-size(tablet-portrait-up) {
		display: none;
	}

	.arrow {
		position: absolute;
		top: 0;
		display: block;
		padding: 20px;

		&.disabled {
			opacity: 0.2;
			pointer-events: none;
		}
	}

	.leftArrow {
		right: 55px;
		padding-right: 15px;
	}

	.rightArrow {
		right: 0;
		padding-right: 15px;
		transform: scaleX(-1);
	}
}
