// Grid

.grid {
	display: grid;

	grid-template-columns: repeat(12, 1fr);

	//grid-template-columns: repeat(3, 1fr);
	//
	//@media (min-width: $media-breakpoint-md) {
	//	grid-template-columns: repeat(4, 1fr);
	//}
	//
	//@media (min-width: $media-breakpoint-xl) {
	//	grid-template-columns: repeat(12, 1fr);
	//}
}

$site-padding-s: 25px;
$site-padding-m: 30px;
$site-padding-l: 30px;
