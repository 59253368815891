$menu-item-padding: 15px 10px;

//todo - remove mobile styles and move them MobileMenu.scss

.Menu {
	position: fixed;
	top: 0;
	right: $site-padding-s;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: 3.64px;
	z-index: 4;
	display: none;

	@include for-size(tablet-portrait-up) {
		display: block;
	}

	&.disabled {
		pointer-events: none;
	}

	.menu-bg {
		top: 0;
		left: 0;
		height: 40px;
		display: block;
		opacity: 1;
		width: 100%;
		position: fixed;
		z-index: 0;
		opacity: 0;

		@include for-size(tablet-portrait-up) {
			display: none;
		}
	}

	a {
		position: relative;
		float: left;

		@include for-size(tablet-portrait-up) {
			padding: $menu-item-padding;
			transform: translateY(-100%);
		}

		&:last-child {
			margin-right: 0;
		}

		.title {
			display: inline-block;
			pointer-events: none;
			font-weight: 400;
		}

		.underline {
			position: absolute;
			width: calc(100% - 23px);
			height: 1px;
			bottom: 8px;
			left: 10px;
			opacity: 0;
			transform: translateY(-3px);
			transition: all 0.6s;
			pointer-events: none;
		}

		&:hover,
		&.active {
			.underline {
				@include for-size(tablet-portrait-up) {
					opacity: 1;
					transform: translateY(0px);
				}
			}
		}
	}

	.backToStart {
		position: fixed;
		top: 0;
		left: $site-padding-s;
		margin: 0;
		padding: 15px;
		display: none;
		transform: none;
		visibility: hidden;
		opacity: 0;

		svg {
			transition: 0.5s transform;
		}

		&:hover {
			svg {
				transform: translateX(-10px);
			}
		}

		@include for-size(tablet-portrait-up) {
			top: 0;
			left: calc(150px + 10%);
			display: block;
		}

		@include for-size(tablet-landscape-up) {
			left: auto;
			right: 660px;
		}
	}

	.hamburger {
		width: 47px;
		height: 20px;
		float: left;
		pointer-events: none;

		.line {
			height: 1px;
			width: 100%;
			background-color: #000;
			margin: 4px 0;
			transition: all 0.4s;
		}
	}

	.projectMenuButton:hover {
		.line:nth-child(1) {
			transform: translateY(-2px);
		}

		.line:nth-child(3) {
			transform: translateY(2px);
		}
	}

	.projectMenuButton.active .hamburger.active {
		.line {
			&:nth-child(1) {
				transform: translateY(5px);
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-5px);
				opacity: 0;
			}
		}
	}

	.projectMenuButton {
		position: fixed;
		top: 0;
		left: $site-padding-s;
		margin: 0;

		@include for-size(tablet-portrait-up) {
			top: 0;
			left: calc(200px + 10%);
			padding-left: 0;

			.title {
				display: none;
			}
		}

		@media (min-width: 800px) {
			.title {
				display: inline-block;
			}
		}

		@include for-size(tablet-landscape-up) {
			left: auto;
			right: 440px;
		}

		.hamburger {
			margin-top: -5px;
			margin-right: 20px;
			display: none;
			pointer-events: none;

			@include for-size(tablet-portrait-up) {
				display: block;
			}
		}

		.overviewButton {
			display: none;
			float: left;
			position: relative;
			padding-left: 20px;
			margin-top: 60px;
			pointer-events: none;

			@include for-size(tablet-portrait-up) {
				display: block;
				padding-left: 0;
				margin-top: 0;
			}

			.arrow-icon {
				margin-right: 5px;
				position: absolute;
				top: -1px;
				left: 0;

				@include for-size(tablet-portrait-up) {
					display: none;
				}
			}

			.title {
				width: 75px;
			}
		}
	}

	.menuWrapper {
		position: fixed;
		left: 0;
		top: 35px;
		flex-direction: column;
		padding: $site-padding-s;
		display: none;
		width: 0;
		overflow: visible;

		@include for-size(tablet-portrait-up) {
			display: block;
			width: auto;
			top: auto;
			left: auto;
			position: relative;
			padding: 0;
		}

		a {
			font-size: 30px;
			font-weight: 400;
			padding: 3px 0;

			&:last-child {
				padding-right: 0;

				.underline {
					width: calc(100% - 13px);
				}
			}

			@include for-size(tablet-portrait-up) {
				font-size: 10px;
				font-weight: 300;
				padding: $menu-item-padding;
			}
		}
	}

	.ProjectMenuTemplate {
		display: none;
		opacity: 0;
		will-change: opacity;

		.inner {
			padding: 60px 0 0 0;

			@include for-size(tablet-portrait-up) {
				padding: 0;
			}
		}

		ul {
			position: relative;
			top: 0;
			left: 0;
			padding: 0 $site-padding-s;
			margin-bottom: 30px;

			@include for-size(tablet-portrait-up) {
				top: 0;
				left: calc(200px + 10%);
				padding: 0;
			}

			@media (min-width: 800px) {
				//left: calc(100% + -594px);
			}

			@include for-size(tablet-landscape-up) {
				left: calc(100% + -622px);
			}

			li {
				a {
					display: inline-block;
					line-height: 1.3;
					clear: both;
					font-size: 18px;
					padding: 0 0 1px 0;
					position: relative;
					width: 100%;
					pointer-events: auto;
					opacity: 0.4;

					@include for-size(tablet-portrait-up) {
						font-size: 20px;
						width: auto;
					}

					& > * {
						pointer-events: none;
					}

					.hoverArrow {
						float: left;
						margin-left: -36px;
						padding-right: 0;
						margin-top: -4px;
						visibility: hidden;
						position: absolute;
						top: 0;
						right: 0;

						@include for-size(tablet-portrait-up) {
							position: relative;
							top: auto;
							right: auto;
							padding-right: 10px;
						}
					}

					&.active {
						pointer-events: none;
						opacity: 1 !important;

						.hoverArrow {
							visibility: visible !important;
							opacity: 1 !important;
							transform: matrix(1, 0, 0, 1, 0, 0) !important;
						}
					}

					.itemTitle {
						float: left;
					}
				}
			}
		}
	}
}
