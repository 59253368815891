.about.section {

	.closeRow.gridInlay {
		margin-bottom: 30px;
		text-align: right;
		justify-content: flex-end;

		@include for-size(tablet-portrait-up) {
			margin-bottom: 50px;
		}
	}

	.closeButton {
		padding: 20px;
		margin-right: -20px;
		opacity: 1;
		z-index: 3;

		@include for-size(tablet-portrait-up) {
			opacity: .5;
		}

		@include for-size(desktop-up) {
			position: fixed;
			right: 30px;
			left: auto;
		}

		&:hover {
			opacity: 1;
		}
	}

	.body {
		margin-bottom: 40px;

		p {
			font-size: 18px;
			line-height: 1.25;

			@include for-size(tablet-landscape-up) {
				font-size: 22px;
			}

			@include for-size(desktop-up) {
				font-size: 26px;
			}
		}

		@include for-size(tablet-landscape-up) {
			margin-bottom: 80px;
		}
	}

	.left-col {
		flex: 1;
		margin-bottom: 40px;
		display: flex;
		flex-direction: column;

		@include for-size(tablet-portrait-up) {
			padding-right: 30px;
			margin-bottom: 0;
		}

		@include for-size(desktop-up) {
			padding-right: 75px;
		}
	}

	.right-col {
		width: 100%;
		max-width: 400px;
		box-sizing: content-box;

		@include for-size(tablet-portrait-up) {
			width: 50%;
		}

		@include for-size(tablet-landscape-up) {
			width: 100%;
			padding-left: 75px;
		}

		@include for-size(tablet-landscape-up) {
			max-width: 490px;
		}

		img {
			width: 100%;
		}
	}

	.caption-box {
		position: relative;
		width: 100%;
		padding: 20px;
		margin-left: -10px;
		margin-top: -10px;

		@include for-size(tablet-portrait-up) {
			padding: 30px;
			margin-left: -30px;
			margin-top: -30px;
		}

		.white-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #fff;
			opacity: .5;
		}

		p {
			position: relative;
			color: #000;
			font-size: 14px;
		}

	}

	.audio-wrapper {
		flex-grow: 1;
	}

	.audioCaption {
		max-width: 420px;

		p {
			font-size: 14px;
			line-height: 1.3;
		}
	}

	.audioPlayer {
		display: flex;
		align-items: center;
		margin-top: 20px;

		.AudioButton {
			margin-top: 0;

			.buttonTitle {
				width: auto;
				font-weight: bold;
			}
		}

		.playDuration {
			flex: 1;
			margin-left: 10px;
			margin-right: 20px;
		}

		.playhead {
			.dot {
				border-radius: 50%;
				width: 10px;
				height: 10px;
			}
		}

		.time {
			font-size: 14px;
			font-weight: bold;
		}
	}
}
