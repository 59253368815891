.Projects {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;

	&.slideshow {
		.project {
			pointer-events: none;
		}
	}

	.mask {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.project {
		position: relative;
		z-index: 4;
		display: none;
		opacity: 0;
		will-change: opacity;
		pointer-events: auto;

		> .innerWrapper {
			will-change: transform;
			display: flex;
		}

		.projectTitle {
			font-size: 20px;
			line-height: 23px;
		}

		.backToStart {
			flex: 0 0 auto;

			.wrapper {
				padding: 20px;

				span {
					display: inline-block;
					margin-left: 10px;
				}
			}
		}
	}

	.AudioButton {
		position: absolute;
		will-change: transform;
		top: 0;
		left: 0;
		margin: 0;
		z-index: 5;
	}
}

.projectModule {
	position: relative;
	margin-bottom: 30px;
	margin-right: 80px;
	flex: 0 0 auto;

	@include for-size(tablet-portrait-up) {
		margin-right: 170px;
	}

	.bullet {
		display: inline-block;
		width: 12px;
		height: 12px;
	}

	.index {
		font-size: 14px;
		letter-spacing: 1px;
	}

	.projectModuleTitle {
		font-size: 25px;
		line-height: 28px;
	}

	.projectModuleBody {
		margin-top: 42px;
	}
}
