.CursorManager {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	pointer-events: none;
	display: none;
	will-change: transform;

	html.touch & {
		display: none;
	}

	.cursor {
		will-change: transform;
	}
}
