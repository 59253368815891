.LoaderScreen {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: $color-white;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	color: $color-black;

	.loader {
		display: flex;
		align-items: center;
		justify-content: center;

		.loader-bar {
			width: 100px;
			height: 1px;
			display: inline-block;
			margin: 0 10px;

			.line {
				width: 0;
				height: 1px;
				display: block;
				background-color: $color-black;
			}
		}
	}

	.instructions {
		position: absolute;
		top: calc(50% - 70px);
		left: calc(50% - 200px);
		width: 400px;
		height: 220px;
		display: flex;
		justify-content: center;
		align-items: center;
		visibility: hidden;
		opacity: 0;

		.icon {
			position: absolute;
			top: calc(50% - 70px);
			left: calc(50% - 20px);

			&.left {
				transform: rotate(180deg);
				margin-left: -40px;
				margin-top: 11px;
			}

			&.right {
				margin-left: 60px;
				margin-top: 12px;
			}

			&.drag {

			}

			@include for-size(tablet-portrait-up) {

			}
		}

		p {
			font-size: 9px;
			text-transform: uppercase;
			letter-spacing: 2px;

			@include for-size(tablet-portrait-up) {
				font-size: 10px;
				letter-spacing: 3.5px;
			}
		}
	}
}
