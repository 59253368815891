.ProjectMenu {
	opacity: 0;
	position: relative;
	top: 50px;
	left: 0;
	z-index: 4;
	will-change: opacity, transform;

	.inner {
		padding: 0;
		height: calc(100vh - 50px);

		@include for-size(tablet-portrait-up) {
			padding: 0;
		}
	}
}
