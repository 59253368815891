.InfoSections {
	position: absolute;
	top: 40px;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 3;
	display: none;
	opacity: 0;
	visibility: hidden;

	.scrollContent {
		height: 100%;
		padding-bottom: 300px;
		overflow-y: scroll;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */

		&::-webkit-scrollbar { /* WebKit */
			width: 0;
			height: 0;
		}
	}

	p:not(:last-child) {
		margin-bottom: 25px;
	}

	.topBar {
		position: fixed;
		top: 0;
		left: 0;
		height: 40px;
		width: 100%;
		z-index: 3;
	}

	.section {
		padding-top: 30px;

		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, .1);
			padding-bottom: 50px;

			@include for-size(tablet-portrait-up) {
				padding-bottom: 100px;
			}
		}

		.gridInlay {
			padding: 0 25px;
			margin: 0 auto;

			@include for-size(tablet-portrait-up) {
				padding: 0 30px;
			}

			@include for-size(desktop-up) {
				padding: 0 130px;
			}
		}

		.top-title {
			margin-bottom: 32px;

			@include for-size(tablet-portrait-up) {
				margin-bottom: 85px;
			}
		}

		.title-dot {
			position: static;
			display: block;
			margin-bottom: 10px;
		}

		.inner {
			display: flex;
			flex-direction: column;

			@include for-size(tablet-portrait-up) {
				flex-direction: row;
			}
		}
	}
}
