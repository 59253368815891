.ExhibitionModule.projectModule {
	.innerWrapper {
		display: flex;
		flex-direction: column-reverse;

		@include for-size(tablet-portrait-up) {
			flex-direction: row-reverse;
		}
	}

	.index {
		margin-left: 21px;
	}

	.info {
		width: 100%;
		margin-left: 0;
		margin-right: 40px;
		max-width: 540px;
		will-change: transform;
		pointer-events: none;
	}

	.images {
		margin-left: 36px;

		@include for-size(tablet-portrait-up) {
			margin-left: 0;
		}
	}

	.exhibitionTitle {
		font-size: 40px;
		margin-top: 25px;
		margin-left: 36px;
		max-width: 280px;

		@include for-size(tablet-portrait-up) {
			font-size: 65px;
			margin-top: 42px;
			max-width: none;
		}
	}

	.exhibitionLocationTime {
		font-size: 20px;
		line-height: 1.25;
		margin-top: 55px;
		margin-left: 36px;

		@include for-size(tablet-portrait-up) {
			font-size: 24px;
			margin-top: 45px;
		}
	}

	.projectModuleBody {
		max-width: 320px;
		margin-top: 22px;
		margin-left: 36px;
		margin-bottom: 32px;
		pointer-events: auto;

		p a {
			text-decoration: underline;
		}

		@include for-size(tablet-portrait-up) {
			max-width: 350px;
			margin-top: 32px;
		}
	}
}
