.CollaboratorsModule {
	width: 100%;
	max-width: 540px;

	.collaboratorTitle {
		display: inline-block;
		margin-left: 23px;
		margin-bottom: 68px;
	}

	.projectModuleBody {
		margin: 25px 0 25px 40px;
	}

	ul {
		margin-left: 40px;
		padding-left: 0;
	}

	li {
		border-top: 1px solid;
		display: block;
		padding-top: 11px;
		height: 37px;
		width: 100%;
		overflow: hidden;
		opacity: 0.4;

		&:first-child {
			border-bottom: 0px;
		}

		&:hover {
			opacity: 1;
		}

		&.open {
			opacity: 1;
			.title {
				font-weight: 500;
			}
		}
	}

	.title {
		font-size: 18px;

		.arrow {
			float: right;
			opacity: 0;
		}
	}

	.credits {
		padding-top: 32px;
		padding-bottom: 53px;

		p {
			font-size: 16px;

			a {
				text-decoration: underline;
			}
		}
	}
}
