$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1240px;
$desktop-upper-boundary: 1800px;

// Break Points
@mixin for-size($range) {
	@if $range == phone-only {
		@media (max-width: #{$phone-upper-boundary - 1}) {
			@content;
		}
	} @else if $range == tablet-portrait-up {
		@media (min-width: $phone-upper-boundary) {
			@content;
		}
	} @else if $range == tablet-landscape-up {
		@media (min-width: $tablet-portrait-upper-boundary) {
			@content;
		}
	} @else if $range == desktop-up {
		@media (min-width: $tablet-landscape-upper-boundary) {
			@content;
		}
	} @else if $range == big-desktop-up {
		@media (min-width: $desktop-upper-boundary) {
			@content;
		}
	}
}

@mixin arabic() {
	@at-root html[lang='ar'] #{&} {
		@content;
	}
}

@mixin breakWord() {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
	@each $property in $properties {
		#{$property}: $min-value;
	}
	@media screen and (min-width: $min-vw) {
		@each $property in $properties {
			#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
		}
	}
	@media screen and (min-width: $max-vw) {
		@each $property in $properties {
			#{$property}: $max-value;
		}
	}
}
