.section.press {
	.pressItem {
		height: 105px;
		overflow: hidden;
		will-change: height;
		opacity: 0.5;

		p,
		p a {
			font-size: 14px;
		}

		@include for-size(tablet-portrait-up) {
			height: 55px;

			p,
			p a {
				font-size: 16px;
			}
		}

		.cta .text {
			text-decoration: underline;
			padding-right: 10px;
			font-weight: 400;
		}

		.cta .icon {
			display: inline-block;
			transition: transform 1s;
		}

		.cta:hover {
			.icon {
				transform: translateX(10px);
			}
		}

		.topRow {
			height: 105px;
			display: flex;
			border-top: 1px solid $color-black;
			justify-content: center;
			flex-direction: column;
			align-items: flex-start;
			position: relative;

			@include for-size(tablet-portrait-up) {
				height: 55px;
				align-items: center;
				justify-content: flex-start;
				flex-direction: row;
			}

			.date {
				width: 33%;
				flex: 0 0 auto;
				padding-right: 40px;
				margin-bottom: 15px;

				@include for-size(tablet-portrait-up) {
					margin-bottom: 0;
				}
			}

			.title {
				font-size: 16px;
				padding-left: 0;
				line-height: 1.3;

				@include for-size(tablet-portrait-up) {
					font-size: 18px;
					padding-left: 40px;
					flex-grow: 1;
				}
			}

			.icon {
				position: absolute;
				top: 18px;
				right: 0;
				transform: scaleY(-1);

				@include for-size(tablet-portrait-up) {
					display: none;
					position: relative;
					top: auto;
					right: auto;
				}
			}
		}

		&.open {
			opacity: 1;

			.title {
				font-weight: 500;
			}

			.topRow .icon {
				transform: scaleY(1);

				@include for-size(tablet-portrait-up) {
					transform: scaleY(1);
				}
			}
		}

		&:hover {
			opacity: 1;

			.topRow .icon {
				@include for-size(tablet-portrait-up) {
					display: block;
				}
			}
		}

		.bottomContent {
			display: flex;
			flex-direction: column;

			.dummy {
				width: 33%;
				flex: 0 0 auto;
				padding-right: 40px;
			}

			.body {
				padding-left: 0;
				padding-bottom: 40px;
				line-height: 1.3;
				max-width: 500px;
			}

			@include for-size(tablet-portrait-up) {
				flex-direction: row;

				.body {
					padding-left: 40px;
				}
			}
		}
	}
}
