.QuoteModule {
	max-width: 650px;
	width: 90vw;

	.index,
	.projectModuleTitle {
		padding-left: 43px;
	}

	.quote {
		font-size: 18px;
		line-height: 1.2;
		margin-bottom: 25px;

		@include for-size(tablet-portrait-up) {
			padding-top: 70px;
			font-size: 30px;
		}
	}

	.quoteSymbol {
		position: absolute;
		top: 27px;
		left: 0;
		font-size: 52px;
	}

	.index {
		margin-bottom: 4px;
	}

	.wrapper {
		border-left: 1px #ccc solid;
		margin-top: 35px;
		margin-left: 8px;
		padding-left: 35px;
	}
}
