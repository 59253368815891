.Sundial {
	position: fixed;
	bottom: 27px;
	left: 20px;
	z-index: 4;
	text-align: center;
	$dial_size_mobile: 30px;
	$dial_size_desktop: 50px;
	pointer-events: none;

	.line {
		height: 1px;
		width: 70px;
	}

	.morningMask {
		position: absolute;
		top: -$dial_size_mobile;
		height: $dial_size_mobile;
		left: 0;
		width: 100%;
		overflow: hidden;
	}

	.nightMask {
		position: absolute;
		top: 0;
		height: $dial_size_mobile;
		left: 0;
		width: 100%;
		overflow: hidden;
	}

	.circle {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
		margin-top: -5px;
		margin-left: -5px;
		width: 10px;
		height: 10px;

		&.morning {
			background-color: #000;
		}

		&.night {
			top: -$dial_size_mobile;
			border: 3px solid #000;
		}
	}

	.time {
		font-size: 8px;
		letter-spacing: 3px;
		margin-top: -12px;
	}

	.label {
		font-size: 9px;
		letter-spacing: 3px;
		margin-top: 12px;
		text-transform: uppercase;
		line-height: 1.3;
		display: none;
	}

	@include for-size(tablet-portrait-up) {
		bottom: 60px;
		left: 30px;

		.time {
			font-size: 9px;
			margin-top: -16px;
		}

		.line {
			width: 140px;
		}

		.morningMask {
			top: -$dial_size_desktop;
			height: $dial_size_desktop;
		}

		.nightMask {
			height: $dial_size_desktop;
		}

		.circle {
			margin-top: -7px;
			margin-left: -7px;
			width: 14px;
			height: 14px;

			&.night {
				top: -$dial_size_desktop;
			}
		}
	}
}
