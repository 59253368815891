.VideoModule {
	.smallUppercase {
		margin-bottom: 13px;
		max-width: 280px;
	}

	.videoDate {
		font-weight: 500;
	}

	.info {
		margin-bottom: 40px;
	}

	.videoWrapper {
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			pointer-events: none;
		}

		img {
			pointer-events: none;
		}
	}
}
