.projectMenuList {
	position: relative;
	top: 0;
	left: 0;
	padding: 0;
	margin-bottom: 30px;
	display: inline-block;

	@include for-size(tablet-portrait-up) {
		text-align: center;
		top: calc(50% - 50px);
		left: 50%;
		transform: translate(-50%, -50%);
	}

	//@media (min-width: 800px) {
	//	//left: calc(100% + -594px);
	//}
	//
	//@include for-size(tablet-landscape-up) {
	//	left: calc(100% + -622px);
	//}

	li {
		a {
			display: inline-block;
			line-height: 1.3;
			font-size: 18px;
			padding: 0;
			position: relative;
			width: 100%;
			pointer-events: auto;
			opacity: 0.2;

			@include for-size(tablet-portrait-up) {
				@include fluid-type(font-size, $tablet-portrait-upper-boundary, $desktop-upper-boundary, 24px, 36px);
				line-height: 2;
				width: auto;
				//display: block;
				//opacity: 0.4;
			}

			& > * {
				pointer-events: none;
			}

			&.active {
				//pointer-events: none;
				opacity: 1;
			}

			.itemTitle {
				position: relative;
			}

			.itemDescription {
				position: absolute;
				bottom: -25px;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0;
				white-space: nowrap;
				display: block;
				font-size: 14px;
			}

			.image-data {
				display: none;
			}
		}
	}
}

.image-col {
	position: absolute;
	top: 0;
	width: 25%;
	height: calc(100% - 50px);
	display: none;

	img {
		position: absolute;
		left: 0;
		width: 100%;
		opacity: 0;
	}

	&.image-left {
		left: 25px;
	}

	&.image-right {
		right: 30px;
	}

	@media (min-width: 1024px) {
		display: block;
	}
}

.contact-me {
	position: absolute;
	left: 50%;
	bottom: 40px;
	transform: translateX(-50%);
	width: 400px;
	text-align: center;
	display: none;
	visibility: hidden;

	@include for-size(tablet-portrait-up) {
		display: block;
	}

	p {
		font-size: 14px;
		a {
			text-decoration: underline;
		}
	}

	@media (min-height: 650px) {
		visibility: visible;
	}
}
