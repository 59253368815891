.AudioButton {
	display: flex;
	align-items: center;
	pointer-events: auto;
	margin-top: 90px;

	@include for-size(tablet-portrait-up) {
		margin-top: 130px;
	}

	circle {
		transition: fill 0.7s;

		rect {
			will-change: transform;
		}
	}

	&:hover {
		circle {
			fill: #fff;
		}
		rect {
			fill: #000;
		}
	}

	.buttonTitle {
		margin-left: 10px;
		font-size: 14px;
		width: 150px;
		line-height: 1.2;
	}
}
