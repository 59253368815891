.CanvasView {
	position: relative;
	overflow: hidden;

	canvas {
		will-change: opacity;
		//filter: blur(0px);
		transition: opacity 0.5s;

		&.blur {
			opacity: 0;
			//filter: blur(15px);
		}
	}

	.blob {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		font-family: $font-nbint-reg;
		font-weight: 300;
		opacity: 0;
		will-change: transform;
		transform: translateX(-1000px);

		.hit-area {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 50%;
			transform-origin: left top;
			will-change: transform;
			width: 1px;
			height: 1px;
			//background-color: red;
		}

		.info {
			position: absolute;
			top: 0;
			left: 0;
			min-width: 200px;
			will-change: transform;

			.bullet {
				margin-bottom: 20px;
			}

			.title {
				font-size: 20px;
				padding-bottom: 20px;
			}

			.description {
				font-size: 14px;
				line-height: 16px;
				max-width: 200px;
			}
		}
	}
}
