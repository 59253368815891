.ImageModule {
	&.imageSideRight {
		.info {
			margin-left: 0;
			margin-right: 40px;
			padding-top: 68px;
		}

		.innerWrapper {
			flex-direction: row-reverse;
		}
	}

	.innerWrapper {
		display: flex;
		flex-direction: column;

		@include for-size(tablet-portrait-up) {
			flex-direction: row;
		}
	}

	.index {
		margin-left: 21px;
	}

	.info {
		margin-top: 30px;
		max-width: 300px;
		will-change: transform;
		pointer-events: auto;

		p a {
			text-decoration: underline;
		}

		@include for-size(tablet-portrait-up) {
			margin-left: 40px;
			margin-top: 0;
		}
	}

	.projectModuleTitle {
		margin-left: 36px;
		margin-top: 10px;
	}
}
