.VideoLoopModule {
	opacity: 0;

	.innerWrapper {
		width: 90vw;
		max-width: 700px;
	}

	video {
		width: 100%;
	}
}
