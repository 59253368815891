.latest.section {

	.gridInlay {
		position: relative;
	}

	.inner {
		overflow: hidden;
		display: inline-block;
		width: 100%;
	}

	.newsListing {
		overflow: hidden;
		width: 100%;
	}

	.scroller {
		display: flex;
	}

	.top {
		margin-bottom: 45px;

		@include for-size(tablet-portrait-up) {
			margin-bottom: 55px;
		}
	}

	.top-title {
		margin-bottom: 45px;

		@include for-size(tablet-portrait-up) {
			margin-bottom: 0;
			float: left;
		}
	}

	.newsItem {
		width: 100%;
		flex: 0 0 auto;

		@include for-size(tablet-portrait-up) {
			width: 33.33%;
		}

		@include for-size(big-desktop-up) {
			width: 25%;
		}

		.newsItemInner {
			padding-right: 20px;

			@include for-size(tablet-portrait-up) {
				padding-right: 60px;
			}
		}

		.top-title {
			height: auto;

			.title {
				margin: 10px 0 20px 0;
				display: inline-block;
				font-size: 24px;
			}
		}

		.category {
			font-size: 12px;
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 35px;
		}

		.date {
			font-size: 18px;
			margin-bottom: 14px;
		}

		.title {
			font-size: 20px;
			margin-bottom: 30px;
		}

		.content a {
			text-decoration: underline;
		}

		h2 {
			font-size: 24px;
			margin: 0 0 1rem 0;
			font-family: $font-nbint-reg;
			line-height: 1.25;
		}

		p {
			font-size: 14px;
		}

		.cta {
			display: block;
			margin-top: 20px;

			.text {
				text-decoration: underline;
				margin-right: 10px;
			}

			.icon {
				display: inline-block;
				transition: .5s transform;

				svg {
					transform: rotate(180deg);
				}
			}

			&:hover {
				.icon {
					transform: translateX(10px);
				}
			}
		}
	}

	.arrows {
		position: absolute;
		top: -8px;
		right: 25px;

		@include for-size(tablet-portrait-up) {
			position: static;
			float: right;
		}

		.arrow {
			padding: 10px;
			display: inline-block;

			&.disabled {
				pointer-events: none;
				opacity: .25;
			}
		}

		.prev {
			transform: scaleX(-1);
		}
	}

	.filters {
		@include for-size(tablet-portrait-up) {
			margin-bottom: 0;
			margin-right: -18px;
			float: right;
		}

		li {
			float: left;
			margin-left: -18px;

			&:last-child {
				a {
					padding-right: 0;

					@include for-size(tablet-portrait-up) {
						padding-right: 18px;
					}
				}
			}

			a {
				padding: 18px;
				font-size: 12px;
				text-transform: uppercase;
				font-weight: bold;
				opacity: .5;

				&:hover,
				&.active {
					opacity: 1;
				}
			}
		}
	}
}
