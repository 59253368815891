@import 'eases';

$color-bg: #fdfbf2 !default;
$color-text-light: #e7e5df !default;
$color-black: #000000;
$color-grey: #414141;
$color-white: #ffffff;
$color-blue: blue;

// Animation
$default-ease: $ease-in-out-quad;

// Font stack
$font-family: 'NB International', 'Helvetica', 'Times New Roman', Times, serif !default;

// Layout
$max-page-width: 1680px;

// Elements
$header-height: 60px !default;
$subpage-content-margin-top-mobile: 40px;
$subpage-content-margin-top: 128px;
